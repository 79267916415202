import React from "react";

interface ICTA {
    bgColor: string;
    contactButtonColor: string;
    demoButtonColor?: string;
    header: string;
    subHeader: string;
    demo?: boolean;
    demoLink?: string;
}

export default function CTA(props: ICTA) {
    return (
        <div className={props.bgColor}>
            <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <h2 className="text-3xl font-header font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    <span className="block">{props.header}</span>
                    <span className="block">{props.subHeader}</span>
                </h2>
                <div className="mt-8 flex justify-center">
                    <div className="inline-flex rounded-md shadow">
                        <a
                            href="/om#kontakt"
                            className={"inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white hover:bg-opacity-60 " + props.contactButtonColor}
                        >
                            Kontakt oss
                        </a>
                    </div>
                    {props.demo ?
                        <div className="ml-3 inline-flex">
                            <a
                                href={props.demoLink ? props.demoLink : "https://outlook.office365.com/owa/calendar/kinetik1@convivial.no/bookings/"}
                                target="_new"
                                className={"inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md bg-white hover:bg-opacity-60 " + props.demoButtonColor}
                            >
                                Book demo
                            </a>
                        </div> : null}
                </div>
            </div>
        </div>
    )
}
