import React from 'react';
import { Helmet } from 'react-helmet';

import SiteHeader from '../../../components/SiteHeader/type4';
import SectionHeader from '../../../components/SectionHeader';
import KinetikFeature from '../../../components/KinetikFeature';
import ConsultantFeature from '../../../components/ConsultantFeature';
import Footer from '../../../components/Footer';
import ProjectFeature from '../../../components/ProjectFeature';
import CTA from '../../../components/CTA';
import SiteNavigation from '../../../components/SiteHeader/navigation';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// @ts-ignore
import OeeLogo from '../../../../static/Logos/oee.svg';

// @ts-ignore
import ArneImg from '../../../../static/KinetikKraft/arne-hanto.jpg';

// @ts-ignore
import KinetikKraftMp4 from '../../../../static/KinetikKraft/dashboard.mp4';

// @ts-ignore
import KinetikKraftMobileMp4 from '../../../../static/KinetikKraft/mobile.mp4';

// @ts-ignore
import KinetikKraftDetailsMp4 from '../../../../static/KinetikKraft/details.mp4';

import LogoCloud from '../../../components/LogoCloud';

import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon, CheckIcon } from '@heroicons/react/outline'
import { StaticImage } from 'gatsby-plugin-image';

const tiers = [
    {
        name: '1 kraftverk',
        href: '/om/#kontakt',
        priceMonthly: 4150,
        description: 'Pris pr. kraftverk, med ubegresent antall brukere. Med 1 lisens pr. kraftverk får dere tilgang til følgende:',
        features: [
            'Sanntidsovervåking ',
            'Analyse og maskinlæring ',
            'Økonomi og helse **',
        ],
    },
    {
        name: '2-6 kraftverk',
        href: '/om/#kontakt',
        priceMonthly: 3550,
        description: 'Pris pr. kraftverk, med ubegresent antall brukere. Med 1 lisens pr. kraftverk får dere tilgang til følgende:',
        features: [
            'Sanntidsovervåking ',
            'Analyse og maskinlæring ',
            'Økonomi og helse **',
        ],
    },
    {
        name: '7+ kraftverk',
        href: '/om/#kontakt',
        priceMonthly: 2850,
        description: 'Pris pr. kraftverk, med ubegresent antall brukere. Med 1 lisens pr. kraftverk får dere tilgang til følgende:',
        features: [
            'Sanntidsovervåking ',
            'Analyse og maskinlæring ',
            'Økonomi og helse **',
        ],
    },

]

const overviewFeatures = [
    {
        id: 1,
        name: 'Produksjonsdata i sanntid ',
        description:
            'Kinetik henter data fra SCADA systemet, og presenterer dette i et brukervennlig og oversiktlig grensesnitt. Hvor mye produseres? Hva er temperaturen i styrelager akkurat nå? Dette er noe av det du enkelt vil kunne få svar på.',
        icon: GlobeAltIcon,
    },
    {
        id: 2,
        name: 'På farten eller på kontoret',
        description:
            'Tjenesten tilbys som en nettbasert løsning som vil fungere like godt på en pc, som på mobiltelefon. Det betyr at det er enkelt å sjekke hvordan tilstanden er i kraftverket, uavhengig av hva slags enhet og hvor du måtte befinne deg.',
        icon: ScaleIcon,
    },
    {
        id: 3,
        name: 'Maskinlæring og kunstig intelligens ',
        description:
            'Ved hjelp av maskinlæring og kunstig intelligens fanger systemet opp trender før det oppstår en kostbar driftsstans eller havari. Med analyser som sammenligner predikerte verdier opp mot sanntidsdata, vil dere få svar på hvor dere bør prioritere vedlikehold.',
        icon: LightningBoltIcon,
    },
]
const alertFeatures = [
    {
        id: 1,
        name: 'Verdifullt styringsverktøy',
        description:
            'Med tilstandskarakter på komponenter og kraftverk tilgjengelig, vil Kinetik gi gode indikasjoner på hvor vedlikehold bør utføres. Det gir også svar på estimert nedetid og hvilke kostnader det er snakk om dersom det skulle oppstå noe uventet.',
        icon: AnnotationIcon,
    },
    {
        id: 2,
        name: 'Økonomisk innsikt',
        description:
            'Ved hjelp av integrasjoner og de innsamlede data som finnes, kan man få en oversikt over inntjening, marginer og hvor mye kraftverkene har produsert for i tidsrommet som defineres. Innsikt, som gir høy verdi for de som har økonomisk ansvar for kraftverket.',
        icon: MailIcon,
    },
    {
        id: 3,
        name: 'Nyttig informasjon',
        description:
            'Ved oppstart kan det gjennomføres en 3D-scan av kraftverket og området det ligger i. Med dette tilgjengelig vil det være mulig å visualisere ulike verdier som er nyttige i et kart, i sanntid. Eksempelvis vil det være mulig å visualisere hvor magasinmåling ligger og sanntidsverdi.',
        icon: MailIcon,
    },
]

function KinetikKraft({ props }) {
    return (
        <div className="bg-gray-50">
            <Helmet>
                <title>Convivial | Kinetik Kraft</title>
                <link rel="canonical" href="https://convivial.no/tjenester/kraft" />
                <meta name="description" content="Finn bortgjemte data med Kinetik Kraft" />
                <script async defer data-domain="convivial.no" src="https://plausible.io/js/plausible.js"></script>
            </Helmet>
            <main>
                <SiteNavigation bgColor="bg-gray-50" />
                <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 mb-16">
                    <div className="sm:text-center">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl font-header">
                            <span className="block">Finn bortgjemte data&nbsp;med</span>{' '}
                            <span className="block text-kinetik">Kinetik Kraft</span>
                        </h1>
                        <p className="mt-3 max-w-md sm:mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                            Med Kinetik Kraft får kundene våre en komplett digital oversikt over hva som skjer i kraftverkene, og får samtidig et verktøy som gir indikasjoner og svar på hva som bør prioriteres av vedlikehold.
                        </p>
                    </div>
                </div>

                <div className="bg-gray-50">
                    <div className="relative overflow-hidden">
                        <div className="relative">
                            <div className="absolute inset-0 flex flex-col" aria-hidden="true">
                                <div className="flex-1" />
                                <div className="flex-1 w-full bg-white" />
                            </div>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 pb-6">
                                <video src={KinetikKraftMp4} controls={false} muted autoPlay loop={true} className="relative rounded-lg w-full shadow-lg">
                                    Video not supported in your browser
                                </video>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="bg-white overflow-hidden pb-20 pt-16">
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

                        <div className="relative">
                            <img
                                className="mx-auto h-10"
                                src={OeeLogo}
                                alt="Øvre Eiker Energi"
                            />
                            <blockquote className="mt-10">
                                <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                                    <p>
                                        &ldquo;Løsningen gir ledelse og driftspersonell tilgang til alle nødvendige data fra kraftverkene i porteføljen for å kunne drifte anleggene effektivt, unngå kostbare havarier og utnytte restlevetiden til aggregatene maksimalt.&rdquo;
                                    </p>
                                </div>
                                <footer className="mt-8">
                                    <div className="md:flex md:items-center md:justify-center">
                                        <div className="md:flex-shrink-0">
                                            <StaticImage className="mx-auto h-10 w-10 rounded-full" src="../../../../static/KinetikKraft/arne-hanto.jpg" alt="Arne Hanto Moen" />
                                        </div>
                                        <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                                            <div className="text-base font-medium text-gray-900">Arne Hanto Moen</div>

                                            <svg className="hidden md:block mx-1 h-5 w-5 text-kinetik-dark" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M11 0h3L9 20H6l5-20z" />
                                            </svg>

                                            <div className="text-base font-medium text-gray-500">Adm. dir, Øvre Eiker Energi</div>
                                        </div>
                                    </div>
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                </section>

                <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
                    <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                        <div className="relative">
                            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                Har du full oversikt og kontroll på&nbsp;kraftverkene?
                            </h2>
                            <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                                Med Kinetik Kraft får kundene våre en komplett digital oversikt over hva som skjer i kraftverkene. I tillegg får man et verktøy som gir analyser og svar på hvor og hva som bør prioriteres når vedlikehold skal planlegges.
                            </p>
                        </div>

                        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                            <div className="relative">
                                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                                    Full oversikt
                                </h3>
                                <p className="mt-3 text-lg text-gray-500">
                                    Kinetik Kraft gir unik innsikt i dataene som finnes i kraftverket, i sanntid. Er det noe på gang eller feil i kraftverket? Kinetik gir deg svar.
                                </p>

                                <dl className="mt-10 space-y-10">
                                    {overviewFeatures.map((item) => (
                                        <div key={item.id} className="relative">
                                            <dt>
                                                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-kinetik text-white">
                                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                                </div>
                                                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                                            </dt>
                                            <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>

                            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                                <video src={KinetikKraftMobileMp4} width={360} controls={false} muted autoPlay loop={true} className="relative mx-auto rounded-lg shadow-2xl">
                                    Video not supported in your browser
                                </video>
                            </div>
                        </div>

                        <div className="relative mt-12 sm:mt-16 lg:mt-24">
                            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                                <div className="lg:col-start-2">
                                    <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Helse og økonomi</h3>
                                    <p className="mt-3 text-lg text-gray-500">
                                        Ved hjelp av innsamlede data og andre kilder, vil komponenter og kraftverk få en tilstandskarakter. Svært nyttig i planlegging av vedlikehold.
                                    </p>

                                    <dl className="mt-10 space-y-10">
                                        {alertFeatures.map((item) => (
                                            <div key={item.id} className="relative">
                                                <dt>
                                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-kinetik text-white">
                                                        <item.icon className="h-6 w-6" aria-hidden="true" />
                                                    </div>
                                                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                                                </dt>
                                                <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>

                                <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                                    <video src={KinetikKraftDetailsMp4} width={360} controls={false} muted autoPlay loop={true} className="relative mx-auto rounded-lg shadow-2xl">
                                        Video not supported in your browser
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="bg-kinetik-dark">
                    <div className="pt-12 sm:pt-16 lg:pt-24">
                        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                            <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
                                <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">PRISER</h2>
                                <p className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
                                    Ingen skjulte&nbsp;priser
                                </p>
                                <p className="text-xl text-gray-300">
                                    Skjulte priser eller pris på forespørsel er utrolig irriterende. Vi ønsker å være så transparente som mulig, derfor synliggjør vi hvor mye lisensen i utgangspunktet koster pr.mnd. *
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 bg-gray-50 sm:mt-12 lg:mt-16">
                        <div className="relative">
                            <div className="absolute inset-0 h-3/4 bg-kinetik-dark" />
                            <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-3 lg:gap-5 lg:space-y-0 ">
                                    {tiers.map((tier) => (
                                        <div key={tier.name} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                            <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                                                <div>
                                                    <h3
                                                        className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-kinetik text-kinetik-dark"
                                                        id="tier-standard"
                                                    >
                                                        {tier.name}
                                                    </h3>
                                                </div>
                                                <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                                                    {tier.priceMonthly}
                                                    <span className="ml-1 text-2xl font-medium text-gray-500">kr/mnd</span>
                                                </div>
                                                <p className="mt-5 text-lg text-gray-500">{tier.description}</p>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                                                <ul className="space-y-4">
                                                    {tier.features.map((feature) => (
                                                        <li key={feature} className="flex items-start">
                                                            <div className="flex-shrink-0">
                                                                <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
                                                            </div>
                                                            <p className="ml-3 text-base text-gray-700">{feature}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className="rounded-md shadow">
                                                    <a
                                                        href={tier.href}
                                                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                                                        aria-describedby="tier-standard"
                                                    >
                                                        Ta kontakt
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
                            <div className="max-w-md mx-auto lg:max-w-5xl">
                                <div className="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center text-gray-400">
                                    * Det vil påløpe et engangsbeløp for implementering og oppstart, og dette vil prises i forkant basert på omfang.
                                    <br />
                                    ** Under utvikling
                                    <br />
                                    Kinetik Nett leveres som en SaaS, som betyr at drift og vedlikehold er inkludert i prisen
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CTA
                    demo={true}
                    demoLink="https://outlook.office365.com/owa/calendar/kinetik1@convivial.no/bookings/s/EH739ZuwDkOIAYITsvGiKA2"
                    header="Fortsatt nysgjerrig?"
                    subHeader="Book en demo eller kontakt&nbsp;oss."
                    bgColor="bg-gray-50"
                    contactButtonColor="bg-kinetik"
                    demoButtonColor="text-kinetik"
                />

            </main>
            <Footer />
        </div>
    );
}

export default KinetikKraft;
